.wrapper {
  min-width: 1200px;
}
.header {
  background: #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Syne;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  z-index: 100;

  .header-inner {
    width: 100%;
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-left {
      display: flex;
      gap: 8px;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
      }
      span {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 166.667% */
      }
    }
    .header-right {
      display: block;
    }
    .header-right-h5 {
      display: none;
    }
  }
}

.home {
  height: 100vh;
  width: 100%;
  background-image: url("../imgs/banner-bg.png");
  background-color: #cfff25;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  .home-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;

    .left {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .left-inner {
        .title {
          color: #000;
          font-family: Syne;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px; /* 125% */
          display: flex;
          gap: 4px;
          .title-r {
            color: #cfff25;
            background: #000;
            padding: 0 4px;
          }
        }
        .desc {
          width: 600px;
          max-width: 600px;
          color: #000;
          font-family: Syne;
          font-size: 80px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 40px;
        }
        a {
          text-decoration: none;
          display: inline-flex;
          padding: 14px 48px;
          align-items: center;
          gap: 10px;
          background: #000;
          color: #cfff25;
          font-family: Syne;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 125% */
          &:hover {
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0.1) 100%
              ),
              #000;
          }
        }
        .next {
          position: absolute;
          bottom: 20px;
        }
      }
    }
    .right {
      width: 50%;
    }
  }
}

.about {
  height: 810px;
  width: 100%;
  background: #111;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  color: #fff;
  background-image: url("../imgs/aboutus-bg.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;

  .about-inner {
    width: 1200px;
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    .title {
      color: #fff;
      font-family: Syne;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .content-img {
      width: 24px;
      height: 56px;
    }
    .top {
      display: flex;
      .list-box {
        display: flex;
        flex-direction: column;
        padding: 16px;
        width: 300px;
        box-sizing: border-box;
        justify-content: space-between;
        height: 180px;
        outline: 1px solid #292929;
        background: #111;

        .content {
          display: flex;
          justify-content: space-between;

          .content-count {
            color: #fff;
            text-align: right;
            font-family: Syne;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 1s ease-out, transform 1s ease-out;

            &.visible {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }
    }
    .bottom {
      display: flex;
      height: 100%;
      height: 510px;
      margin-top: -1px;
      .left {
        outline: 1px solid #292929;
        width: 300px;
        box-sizing: border-box;
        padding: 16px 20px 24px 16px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        min-width: 300px;
        background: #111;

        .bottom-count {
          color: #fff;
          text-align: right;
          font-family: Syne;
          font-size: 96px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 250px;
          opacity: 0;
          transform: translateY(50px);
          transition: opacity 1s ease-out, transform 1s ease-out;

          &.visible {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .bottom-img {
          width: 56px;
          height: 24px;
        }

        .content-img {
          display: none;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        width: 900px;
        .right-top {
          padding: 32px 80px 32px 80px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          outline: 1px solid #292929;
          min-height: 360px;
          box-sizing: border-box;
          height: 360px;
          background: #111;
          .label {
            color: #cfff25;
            font-family: Syne;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }
          .desc {
            color: rgba(255, 255, 255, 0.8);
            font-family: Syne;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            flex-direction: column;
            gap: 24px;
            box-sizing: border-box;
          }
        }
        .right-bottom {
          display: flex;
          flex-direction: row-reverse;
          outline: 1px solid #292929;
          height: 100%;
          padding: 62px 20px 0 0;
          background: #111;
          img {
            width: 48px;
          }
        }
      }
    }
  }
}

.portfolio {
  width: 100%;
  background: #f5f5f5;
  padding: 80px 0;
  display: flex;
  justify-content: center;
  background-image: url("../imgs/portfolio-bg.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  .portfolio-inner {
    width: 1200px;
    .title {
      color: #111;
      font-family: Syne;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 100% */
      text-transform: uppercase;
      margin-bottom: 32px;
    }
    .content {
      .btn-group {
        display: flex;
        gap: 16px;
        overflow: auto;
        button {
          display: inline-flex;
          padding: 10px 24px;
          align-items: center;
          gap: 10px;
          border: 1px solid #000;
          color: #000;
          font-family: Syne;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 125% */
          cursor: pointer;
          &:hover {
            background: #000;
            color: #cfff25;
            // border: none;
          }
        }
        .active {
          background: #000;
          color: #cfff25;
        }
      }
      .detail {
        display: flex;
        gap: 24px;
        margin-top: 32px;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 24px;
          background: #fff;
          min-width: 282px;
          width: 282px;
          height: 506px;
          box-sizing: border-box;
          font-family: Syne;
          color: #000;

          .name {
            font-size: 28px;
            font-weight: 700;
          }
          .desc {
            font-size: 14px;
            font-weight: 600;
          }
          .value {
            font-size: 40px;
            font-weight: 600;
            margin-top: 10px;
          }
        }
        .right {
          width: 100%;
          padding: 24px;
          background: #fff;
          display: flex;
          flex-wrap: wrap;
          row-gap: 16px;
          column-gap: 24px;
          .item {
            display: flex;
            flex-direction: column;
            padding: 32px 0px;
            justify-content: center;
            align-items: center;
            width: 266px;
            height: 142px;
            box-sizing: border-box;
            gap: 10px;
            cursor: pointer;
            background-image: url("../imgs/share.svg");
            background-repeat: no-repeat;
            background-position: top 16px right 16px;
            text-decoration: none;

            &:hover {
              background-color: #111;
              span {
                color: #fff;
              }
            }
            img {
              width: 48px;
            }
            span {
              color: #000;
              text-align: center;
              font-family: Syne;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 142.857% */
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.contactus {
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 94px 0;
  .contactus-inner {
    width: 1200px;
    display: flex;
    gap: 120px;
    .left {
      .title {
        color: #0a0a0a;
        font-family: Syne;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin: 66px 0 28px;
      }
      .desc {
        color: #0a0a0a;
        font-family: Syne;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
      }
      img {
        width: 240px;
        margin-top: 202px;
      }
    }
    .right {
      width: 588px;
      height: 622px;
      border: 1px solid #000;
      box-sizing: border-box;
      padding: 48px 48px 0 48px;

      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        h3 {
          color: #000;
          font-family: Syne;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px; /* 160% */
          text-transform: capitalize;
        }

        span {
          color: rgba(0, 0, 0, 0.8);
          text-align: right;
          font-family: Syne;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px; /* 171.429% */
        }
      }
    }
  }
}

.footer {
  width: 100%;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .footer-inner {
    width: 1200px;
    padding: 34px 0 106px;

    .top {
      display: flex;
      justify-content: space-between;
      .left {
        .title {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 46px 0 70px;
          span {
            color: #cfff25;
            font-family: Syne;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
        .desc {
          color: #cfff25;
          font-family: Syne;
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 88px; /* 183.333% */
          text-transform: uppercase;
          padding: 40px 0 80px;
          border-top: 1px solid #cfff25;
          width: 800px;
        }
        .contactbox {
          display: flex;
          gap: 40px;
          .contact {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 240px;
            span {
              color: #fff;
              font-family: Syne;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
              text-transform: capitalize;
              opacity: 0.4;
            }
            .media {
              width: 24px;
            }
            .email {
              width: 20px;
            }
            p {
              color: rgba(255, 255, 255, 0.8);
              font-family: Syne;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 32px; /* 228.571% */
            }
            .emailbox {
              display: flex;
              align-items: center;
              gap: 8px;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .footer-bottom-inner {
    width: 1200px;
    padding: 44px 0;
    display: flex;
    justify-content: space-between;
    .copyright {
      color: #fff;
      font-family: Syne;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px; /* 142.857% */
      opacity: 0.4;
    }
    img {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    min-width: 100%;
    box-sizing: border-box;
  }
  .header {
    background: #000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Syne;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    z-index: 100;

    .header-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      .header-left {
        display: flex;
        gap: 8px;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
        }
        span {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px; /* 166.667% */
        }
      }
      .header-right {
        display: none;
      }
      .header-right-h5 {
        display: block;
      }
      .m-nav-box {
        position: fixed;
        top: 64px;
        left: 0;
        width: 100%;
        height: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: height 200ms ease-in;
        opacity: 0.9;
        background: #000;
        .m-nav-inner {
          margin-top: 50px;
        }
      }
    }
  }
  .home {
    height: auto;
    width: 100%;
    background-image: url("../imgs/h5/bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    padding-top: 193.07%;
    .home-inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      box-sizing: border-box;

      .left {
        width: 100%;
        margin-top: 160px;
        .left-inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            color: #000;
            font-family: Syne;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px; /* 125% */
            display: flex;
            gap: 4px;
            .title-r {
              color: #cfff25;
              background: #000;
              padding: 0 4px;
            }
          }
          .desc {
            width: 100%;
            max-width: 100%;
            color: #000;
            font-family: Syne;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 16px auto 40px;
            padding: 0 20px;
            text-align: center;
            box-sizing: border-box;
          }
          a {
            text-decoration: none;
            display: inline-flex;
            padding: 14px 48px;
            align-items: center;
            gap: 10px;
            background: #000;
            color: #cfff25;
            font-family: Syne;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            &:hover {
              background: linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 0.1) 0%,
                  rgba(255, 255, 255, 0.1) 100%
                ),
                #000;
            }
          }
          .next {
            display: none;
          }
        }
      }
      .right {
        display: none;
      }
    }
  }
  .about {
    height: auto;
    width: 100%;
    background: #111;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    color: #fff;

    .about-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 40px 20px;
      box-sizing: border-box;
      .title {
        color: #fff;
        font-family: Syne;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .content-img {
        width: 24px;
        height: 56px;
      }
      .top {
        display: flex;
        order: 2;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        .list-box {
          display: flex;
          flex-direction: column;
          padding: 16px;
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;
          height: 180px;

          .content {
            display: flex;
            justify-content: space-between;

            .content-count {
              color: #fff;
              text-align: right;
              font-family: Syne;
              font-size: 48px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
      .bottom {
        display: flex;
        height: 100%;
        height: 510px;
        order: 1;
        flex-direction: column;
        .left {
          width: 100%;
          box-sizing: border-box;
          padding: 16px 20px 24px 16px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          min-width: 100%;
          order: 2;
          margin: 32px 0 0 0;

          .content {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;

            .bottom-count {
              color: #fff;
              text-align: right;
              font-family: Syne;
              font-size: 48px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 0px;
            }

            .bottom-img {
              width: 56px;
              height: 24px;
              display: none;
            }

            .content-img {
              display: block;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          width: 100%;
          order: 1;
          .right-top {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 24px;
            border: none;
            min-height: auto;
            box-sizing: border-box;
            height: auto;
            outline: none;
            .label {
              color: #cfff25;
              font-family: Syne;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-transform: uppercase;
              text-align: center;
            }
            .desc {
              color: #fff;
              font-family: Syne;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              display: flex;
              flex-direction: column;
              gap: 24px;
              width: 100%;
              box-sizing: border-box;
              text-align: center;
            }
          }
          .right-bottom {
            display: flex;
            flex-direction: row-reverse;
            height: 100%;
            padding-top: 62px;
            display: none;
            img {
              width: 48px;
            }
          }
        }
      }
    }
  }
  .portfolio {
    width: 100%;
    background: #f5f5f5;
    padding: 40px 0px 40px 20px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    background-image: url("../imgs/h5/portfolio-bg.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .portfolio-inner {
      width: 100%;
      .title {
        color: #111;
        font-family: Syne;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 100% */
        text-transform: uppercase;
        margin-bottom: 16px;
      }
      .content {
        .btn-group {
          gap: 8px;
          &::-webkit-scrollbar {
            display: none; /* 隐藏滚动条 */
          }
          button {
            display: block;
            padding: 6px 10px;
            align-items: center;
            border: 1px solid #000;
            color: #000;
            font-family: Syne;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 125% */
            cursor: pointer;
            white-space: nowrap;
            &:hover {
              background: #000;
              color: #cfff25;
            }
          }
          .active {
            background: #000;
            color: #cfff25;
          }
        }
        .detail {
          display: flex;
          gap: 16px;
          margin-top: 16px;
          flex-direction: column;
          padding-right: 20px;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 24px;
            background: #fff;
            min-width: 282px;
            width: 100%;
            height: 160px;
            box-sizing: border-box;
            font-family: Syne;
            color: #000;

            .name {
              font-size: 20px;
              font-weight: 700;
            }
            .desc-box {
              align-self: flex-end;
              .desc {
                font-size: 12px;
                font-weight: 500;
              }
              .value {
                font-size: 32px;
                font-weight: 600;
                margin-top: 8px;
                float: right;
              }
            }
          }
          .right {
            width: 100%;
            padding: 8px;
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            row-gap: 8px;
            column-gap: 8px;
            box-sizing: border-box;
            .item {
              display: flex;
              flex-direction: column;
              padding: 32px 46px;
              justify-content: center;
              align-items: center;
              width: 48%;
              height: auto;
              box-sizing: border-box;
              gap: 10px;
              cursor: pointer;
              background-image: url("../imgs/share.svg");
              background-repeat: no-repeat;
              background-position: top 16px right 16px;
              text-decoration: none;

              &:hover {
                background-color: #111;
                span {
                  color: #fff;
                }
              }
              img {
                width: 48px;
              }
              span {
                color: #000;
                text-align: center;
                font-family: Syne;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
  .contactus {
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 40px 20px;
    box-sizing: border-box;
    .contactus-inner {
      width: 100%;
      display: flex;
      gap: 24px;
      flex-direction: column;
      .left {
        .title {
          color: #0a0a0a;
          font-family: Syne;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          text-transform: uppercase;
          margin: 0 0 8px;
        }
        .desc {
          color: #0a0a0a;
          font-family: Syne;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        img {
          width: 240px;
          margin-top: 202px;
          display: none;
        }
      }
      .right {
        width: 100%;
        height: auto;
        border: 1px solid #000;
        box-sizing: border-box;
        padding: 20px 20px 20px 20px;

        .title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;

          h3 {
            color: #000;
            font-family: Syne;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 160% */
            text-transform: capitalize;
          }

          span {
            color: rgba(0, 0, 0, 0.8);
            text-align: right;
            font-family: Syne;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 171.429% */
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    background: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .footer-inner {
      width: 100%;
      padding: 40px 20px 80px;
      box-sizing: border-box;
      .top {
        display: flex;
        justify-content: space-between;
        .left {
          .title {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 0 24px;
            img {
              width: 32px;
            }
            span {
              color: #cfff25;
              font-family: Syne;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
          .desc {
            color: #cfff25;
            font-family: Syne;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px; /* 183.333% */
            text-transform: uppercase;
            padding: 24px 0 32px;
            border-top: 1px solid #cfff25;
            width: 100%;
          }
          .contactbox {
            display: flex;
            gap: 24px;
            flex-direction: column;
            .contact {
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 100%;
              span {
                color: #fff;
                font-family: Syne;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 142.857% */
                text-transform: capitalize;
                opacity: 0.4;
              }
              .media {
                width: 24px;
              }
              .email {
                width: 20px;
              }
              p {
                color: rgba(255, 255, 255, 0.8);
                font-family: Syne;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px; /* 228.571% */
              }
              .emailbox {
                display: flex;
                align-items: center;
                gap: 8px;
                text-decoration: none;
              }
            }
          }
        }
        .right {
          display: none;
        }
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
    .footer-bottom-inner {
      width: 100%;
      padding: 26px 20px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .copyright {
        color: #fff;
        font-family: Syne;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 142.857% */
        opacity: 0.4;
      }
    }
  }
}
