.ant-btn {
  padding: 14px 48px;
  color: #cfff25;
  height: auto;
  border-radius: 0%;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-btn {
  display: inline-flex;
  padding: 14px 48px;
  align-items: center;
  color: #cfff25;
  border-radius: 0px;
  background: #000;
  border: 1px solid #000;

  &:not(:disabled):not(.ant-btn-disabled) {
    &:hover,
    &:active {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0.1) 100%
        ),
        #000;
    }
  }
}

.ant-anchor-wrapper-horizontal {
  .ant-anchor {
    .ant-anchor-link {
      padding-inline: 40px 0;
      padding-block: 0;
      .ant-anchor-link-title {
        color: #fff;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 250% */
        text-transform: uppercase;
        cursor: pointer;
        font-family: Syne;
      }
    }
    .ant-anchor-link-active {
      .ant-anchor-link-title {
        color: #cfff25;
      }
    }
  }
}

.ant-anchor-wrapper {
    .ant-anchor {
        .ant-anchor-link {
        //   padding-inline: 40px 0;
        //   padding-block: 0;
          .ant-anchor-link-title {
            color: #fff;
            text-align: right;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px; /* 250% */
            text-transform: uppercase;
            cursor: pointer;
            font-family: Syne;
            text-align: center;
          }
        }
        .ant-anchor-link-active {
          .ant-anchor-link-title {
            color: #cfff25;
          }
        }
      }
}

.ant-form-item {
  margin-bottom: 32px;
  font-family: Syne;
}

.ant-form-item-label {
  > label {
    font-size: 14px;
    font-style: normal;
    font-family: Syne;
    color: #000;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    text-transform: uppercase;

    &.ant-form-item-required {
      &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
        &::before {
          display: none;
        }
      }
    }
  }
}

.ant-input {
  width: 100%;
  height: 48px;
  border-radius: 0px;
  padding: 12px 24px;
  background: #fff;
  border: 1px solid #000;
  font-family: Syne;
}

textarea.ant-input {
  height: auto;
  padding: 12px 24px;
  resize: none;
}
